<template>
  <v-sheet class="treemap">
    <div class="text-subtitle ma-2 text-center font-weight-light">
      <div class="text-subtitle-2">
        {{ $vuetify.lang.t("$vuetify.dashboard.lowBalanceTitle") }}
        <v-icon @click="showSettings = true" class="ma-2 pa-1 rounded" v-ripple>
          mdi-tune
        </v-icon>
      </div>
      <div class="text-caption">{{ $vuetify.lang.t("$vuetify.dashboard.lowBalanceCaption") }}</div>
    </div>

    <div class="text-center text-caption mb-2">
      {{ $vuetify.lang.t("$vuetify.dashboard.matchFound", belowThreshold.length) }}
    </div>
    <div class="d-flex justify-space-between" style="overflow-x: scroll">
      <div
        v-ripple
        @click="clickedProduct(product)"
        style="cursor: pointer"
        class="mx-2 pa-2 text-center"
        v-for="product in belowThreshold"
        :key="product.id"
      >
        <v-progress-circular
          :rotate="-90"
          :size="80"
          :width="12"
          :value="product.percent"
          color="primary"
        >
          {{ product.percent }}%
        </v-progress-circular>
        <div class="text-caption">
          {{ product.name }}
        </div>
        <div class="text-caption">
          {{ product.balance | formatNumber }}
        </div>
      </div>
    </div>

    <v-dialog style="z-index: 1000" v-model="showSettings">
      <v-card height="500">
        <v-card-title class="justify-end">
          <div>
            <v-btn @click="showSettings = null" icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-card-text>
          <page-params
            pageName="lowBalanceAlert"
            vuexName="lowBalanceSettings"
            :parameters="parameters"
            @onLoad="loadedSavedParams"
          />

          <v-divider class="my-4"/>
          
          <div class="d-flex justify-space-between my-2">
            <v-switch
              v-model="parameters.hideZero"
              class="flex-grow-1"
              label="Hide zero balance"
            >
            </v-switch>
            <v-text-field
              class="flex-shrink-1"
              label="Threshold"
              type="number"
              max="100"
              v-model="parameters.threshold"
              suffix="%"
            />
          </div>

          <product-select
            :key="ts"
            required
            class="mx-2 mb-6"
            v-model="parameters.products"
            return-object
            :multiple="true"
            label="Choose monitoring products"
          />
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn @click="updatedSettings"> Done </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-sheet>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
const CustomDialog = () => import("@/components/common/CustomDialog.vue");
const ProductSelect = () => import("@/components/product/ProductSelect.vue");
const PageParams = () => import("@/components/common/PageParams.vue");

export default {
  props: {
    balanceService: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      productStats: [],
      products: [],
      showSettings: false,
      ts: Date.now(),
    };
  },
  async mounted() {
    this.loadProductStats();
  },
  computed: {
    belowThreshold() {
      return this.productStats.filter((s) => {
        const floatPercent = parseFloat(s.percent);
        if (this.parameters.hideZero) {
          return floatPercent != 0 && floatPercent <= this.parameters.threshold;
        } else {
          return floatPercent <= this.parameters.threshold;
        }
      });
    },
    parameters: {
      get() {
        return this.$store.state.lowBalanceSettings.parameters;
      },
      set(value) {
        this.updateParameters(value);
      },
    },
  },
  components: {
    ProductSelect,
    CustomDialog,
    PageParams,
  },
  methods: {
    ...mapActions("lowBalanceSettings", ["updateParameters"]),

    clickedProduct(product) {
      this.$emit("clickedProduct", product);
    },
    loadProductStats() {
      const startDate = moment().subtract(365, "days").format("YYYY-MM-DD");
      const endDate = moment().format("YYYY-MM-DD");

      const productIds = this.parameters.products.map((p) => p.id);

      const params = {
        start: startDate,
        end: endDate,
        productIds: productIds.join(","),
      };
      this.$axios.get("/balanceSnapshot/stats", { params }).then((resp) => {
        console.log(resp.data);
        this.productStats = resp.data;
        this.productStats.forEach((s) => {
          const wrapper = { id: s.productId };
          const productBalance =
            this.balanceService.getBalanceByProduct(wrapper);
          s.balance = productBalance.quantity;
          const range = s.maxQuantity - s.minQuantity;
          const percent = s.balance > range ? 1 : range ? s.balance / range : 0;
          s.percent = (percent * 100).toFixed(0);
        });

        this.productStats.sort((a, b) => a.percent - b.percent);
      });
    },
    updatedSettings() {
      this.showSettings = false;
      this.loadProductStats();
    },
    loadedSavedParams() {
      this.loadProductStats();
      this.showSettings = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.treemap {
  svg {
    font: 10px sans-serif;
  }
}

.legend {
  white-space: nowrap;
  cursor: pointer;
}

.selected-legend {
  text-decoration: underline;
  text-decoration-color: red;
  text-decoration-thickness: 2px;
}
</style>
